<template>
    <div class="row" v-if="!apiDataIsLoading && apiData">
        <div class="col-lg-12 col-md-12"
             v-if="apiData.account_expire_day_count >= 0 && apiData.account_expire_day_count <= 7">
            <div class="note note-warning">
                <div class="note-icon"><i class="fa fa-info"></i></div>
                <div class="note-content">
                    <h4 class="f-s-16">Alerte Licence {{apiData ? apiData.company_name : appName}} !!!</h4>
                    <p class="f-s-14">
                        ATTENTION !!! Votre Licence d'exploitation <b> expire dans
                        {{apiData.account_expire_day_count|formatNumber}} Jour(s).</b> Pour éviter d'éventuelles
                        interruptions de service, veuillez vous assurer que votre compte est suffisamment
                        approvisionné pour éffectuer un renouvellement automatique.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12" v-if="apiData.account_expire_day_count < 0">
            <div class="note note-danger">
                <div class="note-icon"><i class="fa fa-info"></i></div>
                <div class="note-content">
                    <h4 class="f-s-16">Alerte Licence {{apiData ? apiData.company_name : appName}} !!!</h4>
                    <p class="f-s-14">
                        URGENT !!! Votre Licence d'exploitation <b> est arrivé à expiration il y a {{(-1 *
                        apiData.account_expire_day_count)|formatNumber}} Jour(s).</b> Veuillez approvisionner
                        votre compte pour éffectuer un renouvellement automatique.
                    </p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import baseApi from '../../../store/api/base-api'

    export default {
        name: 'company-licence-component',
        components: {},
        props: {},
        computed: {},
        methods: {
            //Initialization Methods
            loadPageScripts() {
            },

            //Other Methods
            async getApiData() {
                this.apiDataIsLoading = true;
                this.apiDataErrorMessage = null;
                try {
                    const response = await baseApi.getCompanyLicenceComponentData();
                    //console.log(response);
                    this.apiData = response.data;
                    this.apiDataIsLoading = false;
                    this.apiDataErrorMessage = null;
                } catch (error) {
                    if (error.response) {
                        //Server responded with a status code that is NOT in the HTTP 200 range
                        //console.log("Headers", error.response.headers);
                        //console.log("Status Code", error.response.status);
                        //console.log("Response Data", error.response.data);
                        this.apiDataIsLoading = false;
                        this.apiDataErrorMessage = error.response.data.message;
                    } else {
                        this.apiDataIsLoading = false;
                        this.apiDataErrorMessage = error.message;
                    }
                }
            },
        },
        data: function () {
            return {
                appName : window.appConfig.appName,
                appCurrency: window.appConfig.appCurrency,
                intervalId01: 0,
                //API Data
                //
                apiData: null,
                apiDataIsLoading: false,
                apiDataErrorMessage: null,
            };
        },
        created: function () {
            this.getApiData();
        },
        mounted: function () {
            this.loadPageScripts();
        },
        beforeDestroy: function () {
        },
    }
</script>
