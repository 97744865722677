/**
 * Here are all the Backend api calls for our modules
 */
import appApiClient from './app-api-client'

export default {
    //
    getTestData() {
        return appApiClient().get('/test');
    },
    postTestFormData(data) {
        const formData = new FormData();
        formData.append('username', data.username);
        formData.append('password', data.password);
        let headers = {};
        if (data.file != null) {
            formData.append('file', data.file);
            headers = {'Content-Type': 'multipart/form-data'};
        }
        return appApiClient().post('/test-form', formData, headers);
    },
    getTestPusherData() {
        return appApiClient().get('/test-pusher');
    },
    //
    getAppLicenceComponentData() {
        return appApiClient().get('/app-licence');
    },
    getAppAccountComponentData() {
        return appApiClient().get('/app-account');
    },
    getCompanyLicenceComponentData() {
        return appApiClient().get('/company-licence');
    },
    getCompanyAccountComponentData() {
        return appApiClient().get('/company-account');
    },
}