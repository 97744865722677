/**
 * Here are all the Backend api calls for our modules
 */
import axios from 'axios'
import apiConfig from '../../config/ApiConfig.vue'
import LocalStorage from '../local-storage'

let AppBaseApiClient = axios.create({
    baseURL : apiConfig.appBaseURI
});

let AppApiClient = function () {
    if(LocalStorage.hasApiToken()){
        AppBaseApiClient.defaults.headers.common['Authorization'] = LocalStorage.getApiTokenHeaderData();
    }
    return AppBaseApiClient;
};

export default AppApiClient

